<template>
  <div class="container">
    <div class="winLoss-content">
      <div class="winLoss">
        <h2>{{ $t('Win_Loss.191') }}</h2>
        <div class="winLoss-search">
          <div class="form-group">
            <label for="">{{ $t('Win_Loss.192') }}</label>
            <b-form-select
              v-model="gameType"
              name="type"
              class="form-control"
              size="1"
            >
              <option value="0">{{ $t('Win_Loss.193') }}</option>
              <!--<option value="2">Game Online</option>-->
              <option value="1">Casino</option>
              <option value="2">Sport Book</option>
              <option value="3">Fish Shooter</option>
              <option value="4">Slot game</option>
            </b-form-select>
          </div>
          <div class="form-group">
            <label for="">{{ $t('Win_Loss.194') }}</label>
            <datepicker
              v-model="fromDate"
              value-type="format"
              input-class="form-control"
              :placeholder="$t('Win_Loss.194')"
              type="date"
            ></datepicker>
          </div>
          <div class="form-group">
            <label for="">{{ $t('Win_Loss.195') }}</label>
            <datepicker
              v-model="toDate"
              value-type="format"
              input-class="form-control"
              :placeholder="$t('Win_Loss.195')"
              type="date"
            ></datepicker>
          </div>
          <div class="form-group">
            <label for="">{{ $t('Win_Loss.196') }}</label>
            <button type="button" @click="handleSearch" style="display:block">
              <i class="fa fa-search"></i> {{ $t('Member.147') }}
            </button>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table
          class="table table-vcenter table-bordered table-hover table-condensed"
          v-if="hasItem && reports"
        >
          <thead>
            <tr v-if="gameType == 1">
              <th>{{ $t('Win_Loss.197') }}</th>
              <th>{{ $t('Transaction.58') }}</th>
              <th>{{ $t('Win_Loss.198') }}</th>
              <th>{{ $t('Win_Loss.199') }}</th>
              <th>{{ $t('Win_Loss.200') }}</th>
              <th>{{ $t('Win_Loss.201') }}</th>
              <th>{{ $t('Win_Loss.202') }}</th>
            </tr>
            <tr v-if="gameType == 2">
              <th>{{ $t('Win_Loss.197') }}</th>
              <th>{{ $t('Message.262') }}</th>
              <th>{{ $t('Transaction.58') }}</th>
              <th>{{ $t('Win_Loss.198') }}</th>
              <th>{{ $t('Win_Loss.199') }}</th>
              <th>{{ $t('LeftSideBar.14') }}</th>
            </tr>
            <tr v-if="gameType == 3">
              <th>{{ $t('Win_Loss.197') }}</th>
              <th>{{ $t('Message.268') }}</th>
              <th>{{ $t('Win_Loss.198') }}</th>
              <th>{{ $t('Win_Loss.199') }}</th>
              <th>{{ $t('Win_Loss.200') }}</th>

              <th>{{ $t('LeftSideBar.14') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="gameType == 1">
              <tr v-for="report in reports" :key="report.BetDetail.BetID">
                <td>{{ report.BetDetail.GameID }}</td>
                <td>{{ report.BetDetail.GameType }}</td>
                <td>
                  {{ new Date(report.BetDetail.BetTime).toLocaleString() }}
                </td>
                <td>{{ report.BetDetail.BetAmount }}</td>
                <td>{{ report.BetDetail.ResultAmount }}</td>
                <td>{{ report.BetDetail.Balance }}</td>
                <td>
                  <span
                    :class="
                      report.BetDetail.ResultAmount > 0
                        ? 'badge badge-success'
                        : 'badge badge-danger'
                    "
                    >{{
                      report.BetDetail.ResultAmount > 0 ? 'Win' : 'Loser'
                    }}</span
                  >
                </td>
              </tr>
            </template>
            <template v-if="gameType == 2">
              <tr v-for="report in reports" :key="report.matchid">
                <td>{{ report.matchid }}</td>
                <td>{{ report.username }}</td>
                <td>{{ report.game_code }}</td>
                <td>{{ report.bet_time }}</td>
                <td>{{ report.bet }}</td>
                <td>{{ report.winlose }}</td>
              </tr>
            </template>
            <template v-if="gameType == 3">
              <tr v-for="report in reports" :key="report.matchid">
                <td>{{ report._id }}</td>

                <td>{{ report.GameBet_Type }}</td>
                <td>{{ report.GameBet_datetime }}</td>
                <td>{{ report.GameBet_Amount }}</td>
                <td>{{ report.GameBet_AmountWin }}</td>
                <td>
                  <span
                    :class="
                      report.GameBet_Status == 1
                        ? 'badge badge-success'
                        : 'badge badge-danger'
                    "
                    >{{ report.GameBet_Status == 1 ? 'Lose' : 'win' }}</span
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div v-else class="block-section">{{ $t('Win_Loss.202') }}</div>
      </div>
      <tr v-if="totalPage > 1">
        <td colspan="5" class="text-right">
          <paginate
            v-model="page"
            :page-count="totalPage"
            :click-handler="handlePagination"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          ></paginate>
        </td>
      </tr>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WinLossReportListItem',
  data: () => ({
    // reports: [],
    gameType: 0,
    subAccountId: '',
    fromDate: null,
    toDate: null,
  }),
  created() {
    this.fetchReport();
  },
  computed: {
    ...mapGetters({
      reports: 'wallet/History',
      totalPage: 'wallet/TotalPages',
    }),
    hasItem() {
      if (this.reports) {
        return this.reports?.length > 0;
      }
    },
  },
  methods: {
    async fetchReport() {
      this.$store.dispatch('wallet/req_getHistoryGame', {
        page: this.page,
        fromDate: this.fromDate,
        toDate: this.toDate,
        game: this.gameType,
      });
    },
    handlePagination() {
      this.fetchReport();
    },
    handleSearch() {
      this.fetchReport();
    },
  },
};
</script>
<style lang="scss">
.winLoss-content {
  position: relative;
  z-index: 10;
  .winLoss {
    .mx-datepicker {
      .mx-input-wrapper {
        input {
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(84, 86, 88, 0.452);
          border-left: none;
          border-radius: 15px;
          color: rgb(212, 250, 77);
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
            color: rgb(212, 250, 77);
          }
        }
        i {
          color: rgba(153, 164, 176, 0.8);
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.badge-success {
  background-color: green;
}
.badge-danger {
  background-color: red;
}
.winLoss-content {
  padding: 20px 20px 0px 20px;
  .winLoss {
    width: 100%;
    h2 {
      color: rgba(153, 164, 176, 0.8);
    }
    .winLoss-search {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 210px;
        margin-right: 5px;
        margin-left: 5px;
        label {
          color: rgba(153, 164, 176, 0.8);
        }
        .custom-select {
          border: 1px solid rgb(45, 48, 53);
          background: rgba(84, 86, 88, 0.452)
            url('~@/assets/images/home/arrow.png') right 0.75rem center/8px 10px
            no-repeat;
          border-left: none;
          border-radius: 15px;
          color: rgb(212, 250, 77);
          &:focus {
            background: rgba(84, 86, 88, 0.452)
              url('~@/assets/images/home/arrow.png') right 0.75rem center/8px
              10px no-repeat;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
          }
          option {
            background-color: #292828;
          }
        }
        button {
          height: 100%;
          width: 7.25rem;
          color: #f5f6f7;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: #5da000;
          background-image: conic-gradient(from 1turn, #458802, #7bc514);
          border-radius: 6.25rem;
          font-weight: bold;
          border: none;
        }
      }
    }
  }
  table {
    color: white;
    position: relative;
    border: none;
    border-radius: 20px;
    thead {
      border-radius: 10px;
      tr {
        border-radius: 10px;
        th {
          border: none;
          position: sticky;
          top: 0;
          z-index: 10;
          background: #ffb800;
        }
      }
    }
    tbody {
      tr {
        border: none;
        &:nth-child(odd) {
          color: rgb(185, 181, 181);
          background-color: rgba(90, 84, 84, 0.288);
        }
        &:nth-child(even) {
          color: rgb(223, 223, 226);
        }
        td {
          border: none;
        }
      }
    }
  }
  .table-responsive {
    height: auto;
    max-height: 600px;
    min-height: 600px;
    overflow-y: auto;
    border-radius: 20px;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    //   &::-webkit-scrollbar-thumb {
    //     background: rgb(60, 255, 0);
    //     border-radius: 10px;
    //   }
  }
  .table-hover tbody tr:hover {
    color: rgb(59, 57, 57);
    background-color: rgba(255, 252, 252, 0.89);
  }
  .block-section {
    color: rgba(153, 164, 176, 0.8);
    padding-left: 50px;
  }
}
</style>
